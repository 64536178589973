@import '../../ui_imports';

.ui-card {
  @include card-box-shadow;
  @include card-container;
  @include card-spacing;

  background-color: var(--ux-white);

  .card-title {
    @include font-type-50--bold;
    margin: 0;
  }

  .card-title-link {
    @include font-type-30;
    padding-left: 0.25rem;
    vertical-align: text-top;
  }

  .card-title-helper-text {
    @include font-type-50;
  }

  .card-subtitle {
    @include font-type-30;
    color: var(--ux-gray-700);
    margin: 0;
  }

  .card-title-hr {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

.card {
  &--full-width {
    @include card-container(100%);
  }

  &--no-padding {
    padding: 0;
  }

  &--sand {
    background-color: var(--ux-sand-100);
  }

  &--sidebar-left,
  &--sidebar-right {
    @include media-breakpoint-up(sm) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-sidebar {
        width: 30%;

        &--no-margin {
          width: 40%;
        }
      }

      .card-sidebar-content {
        width: 60%;
      }
    }

    &--unresponsive {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-sidebar {
        width: 30%;
      }

      .card-sidebar-content {
        width: 60%;
      }
    }
  }

  &--sidebar-left {
    .card-sidebar {
      margin-bottom: 1.25rem;
    }

    @include media-breakpoint-up(sm) {
      .card-sidebar {
        margin-right: 10%;
        margin-bottom: 0;

        &--no-margin {
          margin-right: 0;
        }
      }
    }
  }

  &--sidebar-right {
    .card-sidebar {
      margin-top: 1.25rem;
    }

    @include media-breakpoint-up(sm) {
      .card-sidebar {
        margin-left: 10%;
        margin-top: 0;

        &--no-margin {
          margin-left: 0;
        }
      }
    }
  }

  &--sm {
    @include media-breakpoint-up(sm) {
      max-width: $card-width-sm;
    }
  }

  &--xs {
    @include media-breakpoint-up(sm) {
      max-width: $card-width-xs;
    }
  }
}
